import React from 'react';
import {
  ChakraProvider,
  Text,
  Flex,
  Heading,
  Image,
  theme,
} from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={10}
      >
        <LogoArea mb={5} />
        <InfoArea mb={5} />
        <ImagesArea mb={5} />
        <InnovateArea mb={5} />
        <BottomArea />
      </Flex>
    </ChakraProvider>
  );
}

const ImagesArea = () => {
  return (
    <>
      <Flex>
        <Flex m={2} p={2}>
          <Image
            w="300px"
            h="200px"
            src={
              'https://tnm-files.fra1.digitaloceanspaces.com/expo_pic_1.jpeg'
            }
          />
        </Flex>
        <Flex m={2} p={2}>
          <Image
            w="300px"
            h="200px"
            src={
              'https://tnm-files.fra1.digitaloceanspaces.com/expo_pic_2.jpeg'
            }
          />
        </Flex>
        <Flex m={2} p={2}>
          <Image
            w="300px"
            h="200px"
            src={
              'https://tnm-files.fra1.digitaloceanspaces.com/expo_pic_3.jpeg'
            }
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex m={2} p={2}>
          <Image
            w="300px"
            h="200px"
            src={
              'https://tnm-files.fra1.digitaloceanspaces.com/expo_pic_4.jpeg'
            }
          />
        </Flex>
        <Flex m={2} p={2}>
          <Image
            w="300px"
            h="200px"
            src={
              'https://tnm-files.fra1.digitaloceanspaces.com/expo_pic_5.jpeg'
            }
          />
        </Flex>
        <Flex m={2} p={2}>
          <Image
            w="300px"
            h="200px"
            src={
              'https://tnm-files.fra1.digitaloceanspaces.com/expo_pic_6.jpeg'
            }
          />
        </Flex>
      </Flex>
    </>
  );
};

const InnovateArea = () => {
  return (
    <Flex>
      <Flex d="block" textAlign="center" bgColor="gray.100" m={2} p={2}>
        <Heading>Imagine</Heading>
        <Text>
          Everything great starts with a vision, imagining a better way. Share
          your vision with our team so we can strategically approach how to
          create a compelling, memorable brand showcase.
        </Text>
      </Flex>
      <Flex d="block" textAlign="center" bgColor="gray.100" m={2} p={2}>
        <Heading>Innovate</Heading>
        <Text>
          Grow revenue from attendees, exhibitors and sponsors by partnering
          with us to innovate on tech, strategy, design and service. We want to
          co-invest in your growth.
        </Text>
      </Flex>
      <Flex d="block" textAlign="center" bgColor="gray.100" m={2} p={2}>
        <Heading>Experience</Heading>
        <Text>
          Engage people on a deeper level that compels them to be transformed.
          We’ll empathize with your clients, provoke your team, design
          solutions, execute and optimize.
        </Text>
      </Flex>
    </Flex>
  );
};

const LogoArea = () => {
  return (
    <>
      <Flex>
        <Image
          h="120"
          src={'https://tnm-files.fra1.digitaloceanspaces.com/durusu_logo.png'}
        />
      </Flex>
      <Flex>
        <Heading size="lg">You are welcomed by the company DURUSU LTD.</Heading>
      </Flex>
    </>
  );
};

const InfoArea = () => {
  return (
    <Flex>
      <Text>
        DURUSU is an industry leader in expo services. We are specialized in
        arranging all the needs of the companies that want to participate in
        global events and expos.
      </Text>
    </Flex>
  );
};

const BottomArea = () => {
  return (
    <Flex direction="column" textAlign="center" mt="2">
      <Flex>
        <Text fontWeight="bold">
          DURUSU DANISMANLIK ITHALAT VE IHRACAT HIZM.TIC.LTD.STI
        </Text>
      </Flex>
      <Flex>
        <Text fontWeight="bold" mr="2">
          Address:{' '}
        </Text>
        Molla fenari mah.Nur-u osmaniye cad.Orient Bazaar Tic.Mer. No:45/401
        Fatih/ISTANBUL
      </Flex>
      <Flex>
        <Text fontWeight="bold" mr="2">
          Email:{' '}
        </Text>
        ugurhayat71@gmail.com
      </Flex>
    </Flex>
  );
};

export default App;
